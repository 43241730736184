/* tslint:disable */
/* eslint-disable */
/**
 * KU-IAAS API
 * This is a definition of Kyushu University IAAS Application API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: onda@mmj.ne.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateRequest,
    CreateRequestFromJSON,
    CreateRequestToJSON,
    FinishRequest,
    FinishRequestFromJSON,
    FinishRequestToJSON,
    Target,
    TargetFromJSON,
    TargetToJSON,
} from '../models';

export interface CreateResetRequestRequest {
    createRequest: CreateRequest;
}

export interface GetResetRequestRequest {
    token: string;
}

export interface ResetPasswordRequest {
    token: string;
    finishRequest: FinishRequest;
}

/**
 * 
 */
export class ApplicantPasswordsApi extends runtime.BaseAPI {

    /**
     */
    async createResetRequestRaw(requestParameters: CreateResetRequestRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createRequest === null || requestParameters.createRequest === undefined) {
            throw new runtime.RequiredError('createRequest','Required parameter requestParameters.createRequest was null or undefined when calling createResetRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/applicant/password-reset-requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateRequestToJSON(requestParameters.createRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createResetRequest(createRequest: CreateRequest): Promise<void> {
        await this.createResetRequestRaw({ createRequest: createRequest });
    }

    /**
     */
    async getResetRequestRaw(requestParameters: GetResetRequestRequest): Promise<runtime.ApiResponse<Target>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling getResetRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/applicant/password-reset-requests/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TargetFromJSON(jsonValue));
    }

    /**
     */
    async getResetRequest(token: string): Promise<Target> {
        const response = await this.getResetRequestRaw({ token: token });
        return await response.value();
    }

    /**
     */
    async resetPasswordRaw(requestParameters: ResetPasswordRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling resetPassword.');
        }

        if (requestParameters.finishRequest === null || requestParameters.finishRequest === undefined) {
            throw new runtime.RequiredError('finishRequest','Required parameter requestParameters.finishRequest was null or undefined when calling resetPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/applicant/password-reset-requests/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: FinishRequestToJSON(requestParameters.finishRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async resetPassword(token: string, finishRequest: FinishRequest): Promise<void> {
        await this.resetPasswordRaw({ token: token, finishRequest: finishRequest });
    }

}
