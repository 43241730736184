/* tslint:disable */
/* eslint-disable */
/**
 * KU-IAAS API
 * This is a definition of Kyushu University IAAS Application API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: onda@mmj.ne.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmploymentHistoryResponse
 */
export interface EmploymentHistoryResponse {
    /**
     * 
     * @type {string}
     * @memberof EmploymentHistoryResponse
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof EmploymentHistoryResponse
     */
    location?: string;
    /**
     * 
     * @type {Date}
     * @memberof EmploymentHistoryResponse
     */
    durationFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EmploymentHistoryResponse
     */
    durationTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof EmploymentHistoryResponse
     */
    isToThePresent: boolean;
}

export function EmploymentHistoryResponseFromJSON(json: any): EmploymentHistoryResponse {
    return EmploymentHistoryResponseFromJSONTyped(json, false);
}

export function EmploymentHistoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmploymentHistoryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'durationFrom': !exists(json, 'durationFrom') ? undefined : (new Date(json['durationFrom'])),
        'durationTo': !exists(json, 'durationTo') ? undefined : (new Date(json['durationTo'])),
        'isToThePresent': json['isToThePresent'],
    };
}

export function EmploymentHistoryResponseToJSON(value?: EmploymentHistoryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'location': value.location,
        'durationFrom': value.durationFrom === undefined ? undefined : (value.durationFrom.toISOString()),
        'durationTo': value.durationTo === undefined ? undefined : (value.durationTo.toISOString()),
        'isToThePresent': value.isToThePresent,
    };
}


