/* tslint:disable */
/* eslint-disable */
/**
 * KU-IAAS API
 * This is a definition of Kyushu University IAAS Application API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: onda@mmj.ne.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CurrentApplicant
 */
export interface CurrentApplicant {
    /**
     * 
     * @type {boolean}
     * @memberof CurrentApplicant
     */
    signedIn: boolean;
    /**
     * 
     * @type {string}
     * @memberof CurrentApplicant
     */
    email?: string;
}

export function CurrentApplicantFromJSON(json: any): CurrentApplicant {
    return CurrentApplicantFromJSONTyped(json, false);
}

export function CurrentApplicantFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrentApplicant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'signedIn': json['signedIn'],
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}

export function CurrentApplicantToJSON(value?: CurrentApplicant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'signedIn': value.signedIn,
        'email': value.email,
    };
}


