/* tslint:disable */
/* eslint-disable */
/**
 * KU-IAAS API
 * This is a definition of Kyushu University IAAS Application API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: onda@mmj.ne.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuestionCategoryResponse
 */
export interface QuestionCategoryResponse {
    /**
     * 
     * @type {Date}
     * @memberof QuestionCategoryResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof QuestionCategoryResponse
     */
    version: number;
    /**
     * 
     * @type {Date}
     * @memberof QuestionCategoryResponse
     */
    updatedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof QuestionCategoryResponse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionCategoryResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionCategoryResponse
     */
    nameEn: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionCategoryResponse
     */
    state: QuestionCategoryResponseStateEnum;
}

/**
* @export
* @enum {string}
*/
export enum QuestionCategoryResponseStateEnum {
    Active = 'active',
    Hidden = 'hidden'
}

export function QuestionCategoryResponseFromJSON(json: any): QuestionCategoryResponse {
    return QuestionCategoryResponseFromJSONTyped(json, false);
}

export function QuestionCategoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionCategoryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['createdAt'])),
        'version': json['version'],
        'updatedAt': (new Date(json['updatedAt'])),
        'id': json['id'],
        'name': json['name'],
        'nameEn': json['nameEn'],
        'state': json['state'],
    };
}

export function QuestionCategoryResponseToJSON(value?: QuestionCategoryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': (value.createdAt.toISOString()),
        'version': value.version,
        'updatedAt': (value.updatedAt.toISOString()),
        'id': value.id,
        'name': value.name,
        'nameEn': value.nameEn,
        'state': value.state,
    };
}


