/* tslint:disable */
/* eslint-disable */
/**
 * KU-IAAS API
 * This is a definition of Kyushu University IAAS Application API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: onda@mmj.ne.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Attachment,
    AttachmentFromJSON,
    AttachmentFromJSONTyped,
    AttachmentToJSON,
    Option,
    OptionFromJSON,
    OptionFromJSONTyped,
    OptionToJSON,
} from './';

/**
 * 
 * @export
 * @interface SupplementalMaterialResponse
 */
export interface SupplementalMaterialResponse {
    /**
     * 
     * @type {string}
     * @memberof SupplementalMaterialResponse
     */
    typeOther?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplementalMaterialResponse
     */
    score?: string;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof SupplementalMaterialResponse
     */
    attachments?: Array<Attachment>;
    /**
     * 
     * @type {Option}
     * @memberof SupplementalMaterialResponse
     */
    type?: Option;
}

export function SupplementalMaterialResponseFromJSON(json: any): SupplementalMaterialResponse {
    return SupplementalMaterialResponseFromJSONTyped(json, false);
}

export function SupplementalMaterialResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplementalMaterialResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'typeOther': !exists(json, 'typeOther') ? undefined : json['typeOther'],
        'score': !exists(json, 'score') ? undefined : json['score'],
        'attachments': !exists(json, 'attachments') ? undefined : ((json['attachments'] as Array<any>).map(AttachmentFromJSON)),
        'type': !exists(json, 'type') ? undefined : OptionFromJSON(json['type']),
    };
}

export function SupplementalMaterialResponseToJSON(value?: SupplementalMaterialResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'typeOther': value.typeOther,
        'score': value.score,
        'attachments': value.attachments === undefined ? undefined : ((value.attachments as Array<any>).map(AttachmentToJSON)),
        'type': OptionToJSON(value.type),
    };
}


