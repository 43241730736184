import { proxy } from 'valtio'
import { ApplicationResponseProgramPreferredLanguageEnum, CommonApi, Option, OptionGroup } from '../../generated'
import { apiConfig } from '../apiConfig'
import { isJa, Lang } from '../i18n/useLang'
import { getName } from '../util/getName'

/*
mysql> select id, name,name_ja from programs where program_group_id = 2;
+----+-----------------------------------------------------------+---------------------------+
| id | name                                                      | name_ja                   |
+----+-----------------------------------------------------------+---------------------------+
|  5 | Master’s Program                                          | 修士課程                  |
|  6 | Doctoral Program                                          | 博士課程                  |
|  7 | Five-year Doctoral Program(For System Life Sciences only) | 5年一貫制博士課程         |
+----+-----------------------------------------------------------+---------------------------+
3 rows in set (0.01 sec)
*/
export const programIdsForDegreeStudent: number[] = [5,6,7]

export function programPreferredLanguageText(e: ApplicationResponseProgramPreferredLanguageEnum, lang: Lang): string {
  switch (e) {
    case ApplicationResponseProgramPreferredLanguageEnum.En:
      return lang == 'en' ? 'English' : '英語'
    case ApplicationResponseProgramPreferredLanguageEnum.Ja:
      return lang == 'en' ? 'Japanese' : '日本語'
  }
}

export const programsStore = proxy<{ items: OptionGroup[] }>({
  items: [],
})

const api = new CommonApi(apiConfig)
export async function loadPrograms() {
  await api.getPrograms().then((result) => {
    programsStore.items = result
  })
}

export function getProgramsName(id?: number, defaultValue?: string, isOther?: boolean) {
  if (id === undefined) return ``
  const items: Option[] = []
  programsStore.items.forEach((p: OptionGroup) =>
    p.options.forEach((p: Option) => {
      items.push(p)
    })
  )
  return getName(items, id, defaultValue, isOther)
}

export function getProgramsOptions(programs: { items: OptionGroup[] }) {
  const key = isJa() ? 'nameJa' : 'name'
  const result = programs.items.map((c) => ({
    label: c[key],
    options: c.options.map((o) => ({
      ...o,
      value: o.code,
      label: o[key],
    })),
  }))
  result.forEach((r) => {
    r.options.sort((a, b) => {
      if (a.isOther) return 1
      if (b.isOther) return -1
      if (a.label < b.label) {
        return -1
      } else if (a.label === b.label) {
        return 0
      } else {
        return 1
      }
    })
  })
  return result
}
