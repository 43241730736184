/* tslint:disable */
/* eslint-disable */
/**
 * KU-IAAS API
 * This is a definition of Kyushu University IAAS Application API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: onda@mmj.ne.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Link,
    LinkFromJSON,
    LinkFromJSONTyped,
    LinkToJSON,
} from './';

/**
 * 
 * @export
 * @interface Option
 */
export interface Option {
    /**
     * 
     * @type {number}
     * @memberof Option
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Option
     */
    groupId: number;
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    nameJa: string;
    /**
     * 
     * @type {boolean}
     * @memberof Option
     */
    isOther: boolean;
    /**
     * 
     * @type {Link}
     * @memberof Option
     */
    link?: Link;
    /**
     * 
     * @type {boolean}
     * @memberof Option
     */
    readonly hidden: boolean;
}

export function OptionFromJSON(json: any): Option {
    return OptionFromJSONTyped(json, false);
}

export function OptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Option {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'groupId': json['groupId'],
        'code': json['code'],
        'name': json['name'],
        'nameJa': json['nameJa'],
        'isOther': json['isOther'],
        'link': !exists(json, 'link') ? undefined : LinkFromJSON(json['link']),
        'hidden': json['hidden'],
    };
}

export function OptionToJSON(value?: Option | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'groupId': value.groupId,
        'code': value.code,
        'name': value.name,
        'nameJa': value.nameJa,
        'isOther': value.isOther,
        'link': LinkToJSON(value.link),
    };
}


