import {
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react'
import React, { FC, useCallback } from 'react'
import { SubmitErrorHandler, useForm } from 'react-hook-form'
import { useTitle } from 'react-use'
import { useLocation } from 'wouter'
import { apiConfig } from '../../../common/apiConfig'
import { TextLink } from '../../../common/components/TextLink'
import { FormControlWrapper } from '../../../common/form/FormInput'
import { useSending } from '../../../common/form/useSending'
import { useLang } from '../../../common/i18n/useLang'
import { ApplicantRegistrationsApi } from '../../../generated'
import { Button } from '../Button'
import { Title } from '../Title'

interface FormType {
  email: string
}

export const RegistrationRequestForm: FC = () => {
  const { send, sending } = useSending()

  const { t, language } = useLang()
  useTitle(t('PSS 新規会員登録'))

  const { control, handleSubmit, setError } = useForm<FormType>({
    mode: 'onBlur',
  })
  const [, setLocation] = useLocation()

  const signUp = useCallback(
    async (form) => {
      await send(async () => {
        try {
          const api = new ApplicantRegistrationsApi(apiConfig)
          await api.create({ email: form.email })
          setLocation('/registrations-request-complete')
        } catch (error) {
          if (error instanceof Response && error.status === 409) {
            setError('email', {
              type: 'manual',
              message: `登録済メールアドレスです。`,
            })
          } else {
            throw error
          }
        }
      })
    },
    [setError, setLocation, send]
  )

  const onError: SubmitErrorHandler<FormType> = useCallback((error, event) => {
    console.info('submit error', error, event)
  }, [])

  return (
    <div>
      <Title text={t('新規会員登録')} />

      <div className={'mt24'}>
        {t('入力したメールアドレスにパスワード登録するためのURLが送信されます。')}
        <br />
        <span className={'fw700 clrRed'}>
          {t(
            '※ 長期間有効なアドレスを登録ください。もしも数年後再申請される場合、同じメールアドレスが必要になります。'
          )}
          <br />
          {language === 'ja' ? (
            <Text>
              ※
              一度PSSでアカウントを作成した方は、新しく別のアカウントを作成することはできません。最初に作成したアカウントをご利用ください。修正ができない場合はPSD（
              <Link textDecor={'underline'} href={`mailto:pre-admission@jimu.kyushu-u.ac.jp`}>
                pre-admission@jimu.kyushu-u.ac.jp
              </Link>
              ）までお問い合わせください。
            </Text>
          ) : (
            <Text>
              If you already have an PSS ID, please continue with the existing account. Contact us
              <br />(
              <Link textDecor={'underline'} href={`mailto:pre-admission@jimu.kyushu-u.ac.jp`}>
                pre-admission@jimu.kyushu-u.ac.jp
              </Link>
              ) if you cannot revise the application.
            </Text>
          )}
        </span>
      </div>

      <div className={'mt24'}>
        <FormControlWrapper<FormType>
          control={control}
          path={'email'}
          skipNormalize={true}
          options={{
            required: '必須入力です。',
            pattern: {
              value:
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
              message: 'メールアドレスを入力してください。',
            },
          }}
        >
          {({ id, isInvalid, error, ...inputProps }) => (
            <FormControl
              id={id}
              isRequired={true}
              isInvalid={isInvalid ? true : undefined}
              as={'fieldset'}
              display={'flex'}
              flexDir={'row'}
              alignItems={`baseline`}
            >
              <FormLabel w={'10rem'} fontSize={`14px`} fontWeight={700}>
                {t('メールアドレス')}
              </FormLabel>
              <Stack>
                <Input
                  type="email"
                  size={'sm'}
                  w={'24rem'}
                  autoComplete={'email'}
                  maxLength={200}
                  {...inputProps}
                />
                {error?.message && <FormErrorMessage>{t(error.message)}</FormErrorMessage>}
              </Stack>
            </FormControl>
          )}
        </FormControlWrapper>

        <Center className={'mt16'}>
          <Button
            disabled={sending}
            role={'primary'}
            size={'md'}
            text={t('登録')}
            w={'200px'}
            onClick={handleSubmit(signUp, onError)}
          />
        </Center>

        <Center className={'mt8'}>
          <TextLink text={t('キャンセル')} to={'/'} />
        </Center>
      </div>
    </div>
  )
}
