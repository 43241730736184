/* tslint:disable */
/* eslint-disable */
/**
 * KU-IAAS API
 * This is a definition of Kyushu University IAAS Application API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: onda@mmj.ne.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplicationQuestionResponse
 */
export interface ApplicationQuestionResponse {
    /**
     * 
     * @type {number}
     * @memberof ApplicationQuestionResponse
     */
    questionId: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationQuestionResponse
     */
    answerInCheckbox: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationQuestionResponse
     */
    otherNote?: string;
}

export function ApplicationQuestionResponseFromJSON(json: any): ApplicationQuestionResponse {
    return ApplicationQuestionResponseFromJSONTyped(json, false);
}

export function ApplicationQuestionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationQuestionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'questionId': json['questionId'],
        'answerInCheckbox': json['answerInCheckbox'],
        'otherNote': !exists(json, 'otherNote') ? undefined : json['otherNote'],
    };
}

export function ApplicationQuestionResponseToJSON(value?: ApplicationQuestionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'questionId': value.questionId,
        'answerInCheckbox': value.answerInCheckbox,
        'otherNote': value.otherNote,
    };
}


