/* tslint:disable */
/* eslint-disable */
/**
 * KU-IAAS API
 * This is a definition of Kyushu University IAAS Application API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: onda@mmj.ne.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EducationCertificateCategory
 */
export interface EducationCertificateCategory {
    /**
     * 
     * @type {number}
     * @memberof EducationCertificateCategory
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof EducationCertificateCategory
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof EducationCertificateCategory
     */
    nameJa: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof EducationCertificateCategory
     */
    types: Array<number>;
}

export function EducationCertificateCategoryFromJSON(json: any): EducationCertificateCategory {
    return EducationCertificateCategoryFromJSONTyped(json, false);
}

export function EducationCertificateCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): EducationCertificateCategory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'nameJa': json['nameJa'],
        'types': json['types'],
    };
}

export function EducationCertificateCategoryToJSON(value?: EducationCertificateCategory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'nameJa': value.nameJa,
        'types': value.types,
    };
}


