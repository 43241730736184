/* tslint:disable */
/* eslint-disable */
/**
 * KU-IAAS API
 * This is a definition of Kyushu University IAAS Application API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: onda@mmj.ne.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    applicantId: number;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    contentType: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    sha256sum: string;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    size: number;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    typeId: number;
    /**
     * 
     * @type {Date}
     * @memberof Attachment
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    url: string;
}

export function AttachmentFromJSON(json: any): Attachment {
    return AttachmentFromJSONTyped(json, false);
}

export function AttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Attachment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'applicantId': json['applicantId'],
        'name': json['name'],
        'contentType': json['contentType'],
        'sha256sum': json['sha256sum'],
        'size': json['size'],
        'typeId': json['typeId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'url': json['url'],
    };
}

export function AttachmentToJSON(value?: Attachment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'applicantId': value.applicantId,
        'name': value.name,
        'contentType': value.contentType,
        'sha256sum': value.sha256sum,
        'size': value.size,
        'typeId': value.typeId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'url': value.url,
    };
}


