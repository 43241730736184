import { proxy } from 'valtio'
import { CommonApi, Option } from '../../generated'
import { apiConfig } from '../apiConfig'
import { isJa } from '../i18n/useLang'
import { getName } from '../util/getName'

export const languageMaterialTypesStore = proxy<{ items: Option[] }>({
  items: [],
})

const api = new CommonApi(apiConfig)
export async function loadLanguageMaterialTypes() {
  await api.getLanguageMaterialTypes().then((result) => {
    languageMaterialTypesStore.items = result
  })
}

export function getLanguageMaterialTypesName(id?: number, defaultValue?: string) {
  if (!id) return ''
  return getName(languageMaterialTypesStore.items, id, defaultValue)
}

export function getLanguageMaterialTypesOptions(systems: { items: Option[] }, language: "ja" | "en") {
  const key = isJa() ? 'nameJa' : 'name'
  // 英語・日本語がまじっているのでgroupId(LanguageMaterialType.ktで設定)でフィルタリング
  // その他(id=0)は両方に含める  
  var groupId: number
  switch (language) {
    case "en":
      groupId = 1
      break;
    case "ja":
      groupId = 2
      break;
  }
  return systems.items
    .filter((c) => c.groupId == groupId || c.id == 0)
    .map((c) => ({
    ...c,
    value: c.code,
    label: c[key],
  }))
}
