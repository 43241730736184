/* tslint:disable */
/* eslint-disable */
/**
 * KU-IAAS API
 * This is a definition of Kyushu University IAAS Application API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: onda@mmj.ne.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuestionResponse
 */
export interface QuestionResponse {
    /**
     * 
     * @type {Date}
     * @memberof QuestionResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof QuestionResponse
     */
    version: number;
    /**
     * 
     * @type {Date}
     * @memberof QuestionResponse
     */
    updatedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof QuestionResponse
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionResponse
     */
    questionCategoryId: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionResponse
     */
    nameEn: string;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionResponse
     */
    isOther: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuestionResponse
     */
    state: QuestionResponseStateEnum;
}

/**
* @export
* @enum {string}
*/
export enum QuestionResponseStateEnum {
    Active = 'active',
    Hidden = 'hidden'
}

export function QuestionResponseFromJSON(json: any): QuestionResponse {
    return QuestionResponseFromJSONTyped(json, false);
}

export function QuestionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['createdAt'])),
        'version': json['version'],
        'updatedAt': (new Date(json['updatedAt'])),
        'id': json['id'],
        'questionCategoryId': json['questionCategoryId'],
        'name': json['name'],
        'nameEn': json['nameEn'],
        'isOther': json['isOther'],
        'state': json['state'],
    };
}

export function QuestionResponseToJSON(value?: QuestionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': (value.createdAt.toISOString()),
        'version': value.version,
        'updatedAt': (value.updatedAt.toISOString()),
        'id': value.id,
        'questionCategoryId': value.questionCategoryId,
        'name': value.name,
        'nameEn': value.nameEn,
        'isOther': value.isOther,
        'state': value.state,
    };
}


